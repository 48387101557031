import { createSchema, morphism } from 'morphism';
import React from 'react';

import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { BodyProps } from 'libs/growth-platform-brand-system-v2/src/layouts/AccordionWithImage';
import { AccordionSectionProps } from 'libs/growth-platform-brand-system-v2/src/templates/AccordionSection';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import {
  getGoogleAnalyticsId,
  track,
} from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { schemaImageDisabledGatsby } from '../components/schemaImageDisabledGatsby';
import { schemaDatoButton } from '../schemaDatoButton';
import { TitleWithArrow } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2/TitleWithArrow';

export const schemaAccordionSection = createSchema<AccordionSectionProps, any>({
  background: 'backgroundColor',
  body: {
    path: ['body', 'section', 'locale'],
    fn: ({
      body,
      section,
      locale,
    }: {
      body: any[];
      section: string;
      locale: string;
    }) => {
      const _body = body.map((elt, index) => ({
        ...elt,
        section,
        index,
        locale,
      }));
      return morphism(schemaAccordionBody, _body);
    },
  },
  imagesOnTheLeft: 'imagesOnTheLeft',
  subtitle: 'subtitle',
  surtitle: 'surtitle',
  title: 'title',
});

export const schemaAccordionBody = createSchema<
  BodyProps & { toggleProps?: any },
  any
>({
  title: 'title',
  text: 'text',
  button: {
    path: ['button', 'section', 'index', 'locale'],
    fn: ({
      button,
      section,
      index,
      locale,
    }: {
      button: any[];
      section: string;
      index: number;
      locale: string;
    }) => {
      const btn =
        button?.[0] && morphism(schemaDatoButton, { ...button?.[0], locale });
      if (btn) {
        return (
          <ButtonTracking
            component={ButtonV2}
            section={section}
            index={index}
            {...btn}
            title={<TitleWithArrow title={btn?.title} />}
            labelTracking={btn.title}
          />
        );
      }
      return;
    },
  },
  onClick: {
    path: ['title'],
    fn: ({ title }: any) => {
      return () =>
        track({
          gtm: {
            event: 'Toggle Interaction',
            properties: {
              componentName: 'Accordion',
              toggleLabel: title,
              gaClientID: getGoogleAnalyticsId(),
            },
          },
        });
    },
  },
  image: {
    path: 'image',
    fn: (propertieValue: any) => {
      return morphism(schemaImageDisabledGatsby, propertieValue);
    },
  },
  background: 'background',
});
