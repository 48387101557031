import { NavigationButton } from 'libs/growth-platform-brand-system-v2/src/components/NavigationButton';
import { Picto } from 'libs/growth-platform-brand-system-v2/src/components/Picto';
import { applyBgColor } from 'libs/growth-platform-brand-system-v2/src/utils/utils';

import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import { AccordionSection } from 'libs/growth-platform-brand-system-v2/src/templates/AccordionSection';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { graphql } from 'gatsby';
import { PayFitColor } from 'libs/growth-platform-brand-system-v2/src/types/module';
import morphism from 'morphism';
import React from 'react';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaAccordionSection } from '../../morphismSchema/templates/schemaAccordionSection';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import './styles.scss';
import { CustomIframe } from 'libs/growth-platform-brand-system-v2/src/components/CustomIframe';
import { ArcadeScript } from '../../components/Scripts/ArcadeScript';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="PillarPage"
      dataName={'datoCmsPillarPage'}
    />
  );
};

export const query = graphql`
  query DatoPillarPage($locale: String, $id: String) {
    datoCmsPillarPage(locale: $locale, id: { eq: $id }) {
      ...PillarPage
    }
  }
`;

const PillarPage = ({ data, pageContext }: any) => {
  const { ...doc } = data.datoCmsPillarPage;
  if (!doc) return null;
  const { accordions, ..._data } = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });
  const hero = _data.hero;
  const heroImageProps = hero?.image;
  const heroData = morphism(schemaHero, { ...hero, ...heroImageProps });
  const customIframe = doc?.customIframe?.[0];
  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={{ pageType: 'pillar' }}
      templateName="PillarPage"
    >
      <Hero {...heroData} />
      {customIframe &&
        customIframe?.source(
          <div
            className={classNames(
              'IframeSection pt-[34px] md:pt-[52px] pb-[34px] md:pb-[52px]',
              applyBgColor(customIframe.backgroundColor),
            )}
          >
            {customIframe?.source?.includes('arcade.software') && (
              <ArcadeScript />
            )}
            <CustomIframe
              src={customIframe?.source}
              title={customIframe?.title}
            />
          </div>,
        )}
      <div
        className={classNames(
          'flex mb-64 px-24 xl:justify-center overflow-scroll xl:overflow-auto',
          applyBgColor(heroData.backgroundColor),
        )}
      >
        <div
          className={classNames(
            'flex justify-center -mx-12 xl:flex-wrap gap-12',
            accordions?.length <= 3 && 'md:w-full',
            customIframe?.backgroundColor &&
              heroData.backgroundColor !== customIframe.backgroundColor
              ? 'py-88'
              : 'pb-88',
          )}
        >
          {accordions &&
            accordions?.map(
              (
                {
                  picto,
                  surtitle,
                  imagesOnTheLeft,
                  backgroundColor,
                  body,
                  icon,
                  title,
                  ...accordion
                }: any,
                key: number,
              ) => (
                <ButtonTracking
                  component={NavigationButton}
                  key={key}
                  section="Hero_NavigationButton"
                  {...accordion}
                  className={classNames(
                    'w-[240px]',
                    !heroData?.backgroundColor ||
                      heroData?.backgroundColor === 'white'
                      ? 'border-neutral-51'
                      : 'border-transparent',
                  )}
                  labelTracking={accordion.title}
                >
                  <Picto {...picto} />
                  <div className="flex-1 text-left">{surtitle}</div>
                </ButtonTracking>
              ),
            )}
        </div>
      </div>
      {accordions?.map((accordion: any, key: any) => {
        return (
          <Section
            key={key}
            topPadding="half"
            bottomPadding={key === accordions.length - 1 ? 'full' : 'half'}
          >
            <ButtonTracking
              component={AccordionSection}
              {...morphism(schemaAccordionSection, {
                ...accordion,
                section: `${key}_DatoCmsAccordion`,
              })}
              labelTracking={accordion.title}
              background={(hero?.backgroundColor as PayFitColor) || 'blue'}
            />
          </Section>
        );
      })}

      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone {..._data} heroData={heroData} />
      )}
    </Layout>
  );
};

export default PillarPage;
